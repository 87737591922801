<template>
  <div class="createCoupon">
    <page-title :pageTitle="$route.meta.title"></page-title>
    <div class="mainContent">
      <vh-form :model="formData" ref="ruleForm" :rules="rules" label-width="100px">
        <vh-form-item label="优惠券名称" prop="name" class="couponName">
          <vh-input
            v-model="formData.name"
            autocomplete="off"
            type="text"
            :maxlength="50"
            :show-word-limit="true"
            placeholder="请输入优惠券名称"
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="优惠内容" prop="content" class="couponContent">
          <vh-radio-group
            v-model="formData.content"
            class="coupon_method"
            :disabled="source == 'edit'"
          >
            <vh-radio :label="0">
              满
              <vh-input v-model.trim="formData.content_cost" :disabled="source == 'edit'">
                <template slot="append">元</template>
              </vh-input>
              ， 减
              <vh-input v-model.trim="formData.content_off1" :disabled="source == 'edit'">
                <template slot="append">元</template>
              </vh-input>
            </vh-radio>
            <vh-radio :label="1">
              无门槛， 减
              <vh-input v-model.trim="formData.content_off2" :disabled="source == 'edit'">
                <template slot="append">元</template>
              </vh-input>
            </vh-radio>
          </vh-radio-group>
        </vh-form-item>
        <vh-form-item label="使用时间" prop="useTime" class="couponUseTime">
          <vh-radio-group
            v-model="formData.useTime"
            class="coupon_method"
            :disabled="source == 'edit'"
          >
            <vh-radio :label="1">
              领券后立即生效，有效期
              <vh-input
                v-model.number="formData.useTime_val"
                type="number"
                :disabled="source == 'edit'"
              >
                <template slot="append">天</template>
              </vh-input>
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">
                  <p>1.优惠券仅针对直播间使用，不支持脱离直播间使用</p>
                  <p>2.使用时间按照自然天计算</p>
                  <p>
                    3.举例：设置有效期2天可用，用户在8月1日14:00:09时领取优惠券，则该优惠券的使用时间为8月1日14:00:09-8月3日00:00:00
                  </p>
                </div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
            </vh-radio>
            <vh-radio :label="0">
              <vh-date-picker
                v-model="pickDate"
                size="medium"
                value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm"
                type="datetimerange"
                prefix-icon="iconfont-v3 saasicon_date"
                @change="changeDate"
                :unlink-panels="true"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                style="width: 320px"
                class="mr10 mb15"
                :disabled="source == 'edit'"
              />
            </vh-radio>
          </vh-radio-group>
        </vh-form-item>
        <vh-form-item label="适用商品" prop="goods" class="couponGoods">
          <vh-radio-group v-model="formData.goods" :disabled="source == 'edit'">
            <vh-radio :label="0">全部商品可用</vh-radio>
            <vh-radio :label="1">指定商品可用</vh-radio>
            <vh-radio :label="2">指定商品不可用</vh-radio>
          </vh-radio-group>
          <div class="goodsList" v-show="formData.goods != 0">
            <div class="header">
              <vh-button
                type="default"
                round
                plain
                :disabled="source == 'edit'"
                @click="openMaterial"
                v-preventReClick
                v-if="source != 'edit'"
              >
                添加
              </vh-button>
              <span class="tip" v-if="source != 'edit'" v-show="tableData.length != 0">
                最多添加200个商品
              </span>
            </div>
            <div class="list" v-show="tableData.length != 0">
              <vh-table
                :data="tableData"
                tooltip-effect="dark"
                max-height="400"
                header-row-class-name="headerClass"
              >
                <vh-table-column label="图片" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div class="flexBox">
                      <div class="gift-cover">
                        <img :src="scope.row.cover_img" alt="" />
                      </div>
                    </div>
                  </template>
                </vh-table-column>
                <vh-table-column
                  label="商品名称"
                  prop="name"
                  show-overflow-tooltip
                ></vh-table-column>
                <vh-table-column label="原价" prop="price" width="100">
                  <template slot-scope="scope">
                    <p>
                      {{ scope.row.price !== null ? `￥${scope.row.price}` : `--` }}
                    </p>
                  </template>
                </vh-table-column>
                <vh-table-column label="优惠价" prop="discount_price" width="100">
                  <template slot-scope="scope">
                    <p>
                      {{
                        scope.row.discount_price !== null ? `￥${scope.row.discount_price}` : `--`
                      }}
                    </p>
                  </template>
                </vh-table-column>
                <vh-table-column label="操作" width="60" class-name="tools" v-if="source != 'edit'">
                  <template slot-scope="scope">
                    <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                      <div slot="content">删除</div>
                      <span
                        class="iconfont-v3 saasline-delete"
                        @click="toDel(scope.row)"
                        v-if="source != 'edit'"
                      ></span>
                    </vh-tooltip>
                  </template>
                </vh-table-column>
              </vh-table>
            </div>
          </div>
        </vh-form-item>
        <vh-form-item label="使用说明" class="couponMark">
          <vh-input
            type="textarea"
            :rows="4"
            :maxlength="200"
            :show-word-limit="true"
            placeholder="请输入优惠券使用说明"
            v-model="formData.mark"
          ></vh-input>
        </vh-form-item>
      </vh-form>
      <vh-button type="primary" class="save" round @click="saveCoupon" v-preventReClick>
        保存
      </vh-button>
      <ProductMaterial
        @success="getGoods"
        scene="coupon"
        source="account"
        :selectedIds="selectedGoodsIds"
        ref="materialRef"
      ></ProductMaterial>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import { isEmbed } from '../../../utils/utils';
  import ProductMaterial from './material.vue';
  import regRule from '@/utils/reg-rule.js';

  export default {
    name: 'CouponCreate',
    components: {
      PageTitle,
      ProductMaterial
    },
    data() {
      return {
        pickDate: '',
        formData: {
          name: '',
          content: 0,
          useTime: 1,
          goods: 0,
          mark: '',
          content_cost: null,
          content_off1: null,
          content_off2: null,
          useTime_val: null,
          start_date: '',
          end_date: ''
        },
        rules: {
          name: [{ required: true, message: '请输入优惠券名称', trigger: 'blur' }],
          content: [{ required: true, trigger: 'blur' }],
          useTime: [{ required: true, trigger: 'blur' }],
          goods: [{ required: true, trigger: 'blur' }]
        },
        tableData: [], // 列表数据
        tableData_yes: [], // 列表数据
        tableData_no: [], // 列表数据
        vm: null
      };
    },
    watch: {
      'formData.goods'(val) {
        if (val == 1) {
          this.tableData = this.tableData_yes;
        } else if (val == 2) {
          this.tableData = this.tableData_no;
        } else {
          this.tableData = [];
        }
      },
      'formData.content_cost'(val) {
        this.formData.content_cost = (val + '').match(/\d+(\.\d{0,2})?/)
          ? (val + '').match(/\d+(\.\d{0,2})?/)[0]
          : '';
      },
      'formData.content_off1'(val) {
        this.formData.content_off1 = (val + '').match(/\d+(\.\d{0,2})?/)
          ? (val + '').match(/\d+(\.\d{0,2})?/)[0]
          : '';
      },
      'formData.content_off2'(val) {
        this.formData.content_off2 = (val + '').match(/\d+(\.\d{0,2})?/)
          ? (val + '').match(/\d+(\.\d{0,2})?/)[0]
          : '';
      }
    },
    computed: {
      selectedGoodsIds() {
        //暂不回显
        return [] || this.tableData.map(item => item.goods_id);
      },
      source() {
        let path = this.$route.path;
        if (path.indexOf('editCoupon') != -1) {
          //编辑
          return 'edit';
        } else if (path.indexOf('copyCoupon') != -1) {
          //复制
          return 'copy';
        } else {
          //新增
          return 'add';
        }
      }
    },
    created() {
      if (this.source == 'edit' || this.source == 'copy') {
        this.getCouponInfo();
      }
    },
    methods: {
      changeDate(val) {
        console.log(val);
        if (val) {
          this.formData.start_date = val[0];
          this.formData.end_date = val[1];
        } else {
          this.formData.start_date = '';
          this.formData.end_date = '';
        }
      },
      validateNumber(input) {
        const pattern = /^\d+(\.\d{1,2})?$/;
        return pattern.test(input);
      },
      saveCoupon() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            console.log(this.formData);
            if (this.source == 'edit') {
              this.$fetch(
                'updateCoupon',
                {
                  coupon_id: this.$route.params.couponId,
                  coupon_name: this.formData.name,
                  use_desc: this.formData.mark
                },
                {
                  'Content-Type': 'application/json'
                }
              )
                .then(res => {
                  if (res.code == 200) {
                    this.$vhMessage.success('保存成功');
                    this.$router.push(`${isEmbed() ? '/embed' : ''}/interactive/products?tab=1`);
                  } else {
                    this.$vhMessage({
                      message: res.msg || `保存失败`,
                      showClose: true,
                      // duration: 0,
                      type: 'error',
                      customClass: 'zdy-info-box'
                    });
                  }
                })
                .catch(res => {
                  this.$vhMessage({
                    message: res.msg || `保存失败`,
                    showClose: true,
                    // duration: 0,
                    type: 'error',
                    customClass: 'zdy-info-box'
                  });
                });
            } else {
              let params = {
                coupon_name: this.formData.name,
                coupon_type: this.formData.content,
                use_desc: this.formData.mark,
                validity_type: this.formData.useTime,
                applicable_product_type: this.formData.goods
              };
              if (this.formData.content == 0) {
                if (!this.formData.content_cost || !this.formData.content_off1) {
                  this.$vhMessage.warning('优惠内容金额不能为空');
                  return;
                }

                if (
                  parseFloat(this.formData.content_off1 + '') >
                  parseFloat(this.formData.content_cost + '')
                ) {
                  this.$vhMessage.warning('减钱不能大于门槛金额');
                  return;
                }
                if (
                  parseFloat(this.formData.content_cost + '') > 100000 ||
                  parseFloat(this.formData.content_off1 + '') > 100000
                ) {
                  this.$vhMessage.warning('优惠内容金额最大支持输入10w');
                  return;
                }
                if (
                  !this.validateNumber(this.formData.content_cost) ||
                  !this.validateNumber(this.formData.content_off1)
                ) {
                  this.$vhMessage.warning('优惠内容金额最大支持输入小数点后2位');
                  return;
                }
                params.threshold_amount = this.formData.content_cost;
                params.deduction_amount = this.formData.content_off1;
              } else {
                if (!this.formData.content_off2) {
                  this.$vhMessage.warning('优惠内容金额不能为空');
                  return;
                }

                if (parseFloat(this.formData.content_off2 + '') > 100000) {
                  this.$vhMessage.warning('优惠内容金额最大支持输入10w');
                  return;
                }
                if (!this.validateNumber(this.formData.content_off2)) {
                  this.$vhMessage.warning('优惠内容金额最大支持输入小数点后2位');
                  return;
                }
                params.deduction_amount = this.formData.content_off2;
              }
              if (this.formData.useTime == 1) {
                if (!this.formData.useTime_val) {
                  this.$vhMessage.warning('请输入优惠券有效期天数');
                  return;
                }
                if (!regRule.num8.test(this.formData.useTime_val)) {
                  this.$vhMessage.warning('优惠券有效期天数为正整数');
                  return;
                }

                if (this.formData.useTime_val > 365) {
                  this.$vhMessage.warning('优惠券有效期天数最大365天');
                  return;
                }
                if (this.formData.useTime_val < 1) {
                  this.$vhMessage.warning('优惠券有效期天数最小1天');
                  return;
                }
                params.validity_day = this.formData.useTime_val;
              } else {
                if (!this.formData.start_date) {
                  this.$vhMessage.warning('请输入优惠券的开始时间');
                  return;
                } else if (!this.formData.end_date) {
                  this.$vhMessage.warning('请输入优惠券的结束时间');
                  return;
                }
                params.validity_start_time = this.formData.start_date;
                params.validity_end_time = this.formData.end_date;
              }
              let ids = this.tableData.map(e => e.goods_id);
              if (this.formData.goods != 0) {
                if (ids.length == 0) {
                  this.$vhMessage.warning('请选择适用商品');
                  return;
                }
                if (ids.length > 200) {
                  this.$vhMessage.warning('最多选择200个商品');
                  return;
                }
              }
              params.good_ids = ids;
              this.$fetch('createCoupon', params, {
                'Content-Type': 'application/json'
              })
                .then(res => {
                  if (res.code == 200) {
                    this.$vhMessage.success('保存成功');
                    this.$router.push(`${isEmbed() ? '/embed' : ''}/interactive/products?tab=1`);
                  } else {
                    this.$vhMessage({
                      message: res.msg || `保存失败`,
                      showClose: true,
                      // duration: 0,
                      type: 'error',
                      customClass: 'zdy-info-box'
                    });
                  }
                })
                .catch(res => {
                  this.$vhMessage({
                    message: res.message || `保存失败`,
                    showClose: true,
                    // duration: 0,
                    type: 'error',
                    customClass: 'zdy-info-box'
                  });
                });
            }
          }
        });
      },
      openMaterial() {
        if (this.tableData.length >= 200) {
          this.$vhMessage.warning('最多选择200个商品');
          return;
        }
        if (this.formData.goods == 1) {
          this.tableData_yes = this.tableData;
        }
        if (this.formData.goods == 2) {
          this.tableData_no = this.tableData;
        }
        this.$refs.materialRef.open();
      },
      getGoods(res) {
        console.log(res);
        let temp = JSON.parse(JSON.stringify(this.tableData));
        for (let i = 0; i < res.length; i++) {
          const e1 = res[i];
          let includes = false;
          if (temp.length == 200) {
            break;
          }
          for (let j = 0; j < temp.length; j++) {
            const e2 = temp[j];
            if (e2.goods_id == e1.goods_id) {
              includes = true;
              temp.splice(j, 1);
              temp.unshift(e2);
              break;
            }
          }
          if (!includes) {
            temp.unshift(e1);
          }
        }
        this.tableData = temp;
        if (this.formData.goods == 1) {
          this.tableData_yes = this.tableData;
        }
        if (this.formData.goods == 2) {
          this.tableData_no = this.tableData;
        }
      },
      async getCouponInfo() {
        let res = await this.$fetch(
          'getCouponInfo',
          {
            coupon_id: this.$route.params.couponId
          },
          {
            'Content-Type': 'application/json'
          }
        );
        if (res.code == 200 && res.data) {
          let info = res.data;
          this.formData.name = info.coupon_name;
          this.formData.content = parseInt(info.coupon_type + '');
          if (this.formData.content == 0) {
            this.formData.content_cost = info.threshold_amount || '';
            this.formData.content_off1 = info.deduction_amount || '';
          } else {
            this.formData.content_off2 = info.deduction_amount || '';
          }
          this.formData.mark = info.use_desc;
          this.formData.useTime = parseInt(info.validity_type + '');
          if (this.formData.useTime == 1) {
            this.formData.useTime_val = info.validity_day;
          } else {
            this.formData.start_date = info.validity_start_time;
            this.formData.end_date = info.validity_end_time;
            this.pickDate = [this.formData.start_date, this.formData.end_date];
          }
          this.formData.goods = parseInt(info.applicable_product_type + '');
          if (this.formData.goods != 0) {
            this.getCouponGoodsList();
          }
        }
      },
      async getCouponGoodsList() {
        let res = await this.$fetch('getListCouponGoods', {
          coupon_id: this.$route.params.couponId
        });
        if (res.code == 200 && res.data) {
          this.tableData = res.data.list || [];

          if (this.formData.goods == 1) {
            this.tableData_yes = this.tableData;
          }
          if (this.formData.goods == 2) {
            this.tableData_no = this.tableData;
          }
        }
      },
      toDel(row) {
        for (let index = 0; index < this.tableData.length; index++) {
          const element = this.tableData[index];
          if (element.goods_id == row.goods_id) {
            this.tableData.splice(index, 1);
            if (this.vm) {
              this.vm.close();
            }
            this.vm = this.$vhMessage.success('删除成功');

            break;
          }
        }
      }
    }
  };
</script>

<style lang="less">
  .createCoupon .mainContent {
    background-color: #fff;
    padding: 48px;
    .vh-form {
      .vh-input {
        width: 640px;
      }
      .vh-textarea {
        width: 628px;
      }
      .vh-radio-group {
        &.coupon_method {
          .vh-radio {
            display: flex;
            align-items: center;
            &:first-child {
              margin-bottom: 10px;
            }
            .vh-radio__label {
              display: flex;
              align-items: center;
              .vh-input {
                width: 150px;
                margin: 0 8px;
              }
            }
          }
        }
      }
      input[type='number'] {
        line-height: 1px !important;
      }
      .flexBox {
        display: flex;
        align-items: center;
        .name {
          margin-left: 10px;
          text-overflow: ellipsis;
          width: 130px;
          word-break: break-all;
          overflow: hidden;
        }
        .gift-cover {
          display: inline-block;
          width: 48px;
          min-width: 48px;
          height: 48px;
          margin: 3px 0;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #e6e6e6;
          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }
      }
      .goodsList {
        .header {
          .vh-button {
            margin-right: 10px;
            width: 76px;
            height: 36px;
            padding: 0;
          }
          .tip {
            color: #c8c8c8;
          }
        }
        .list {
          padding-top: 10px;
          .vh-table {
            width: 500px;
            .headerClass {
              background: #f7f7f7;

              height: 40px;
              th {
                padding: 0;
                color: rgba(0, 0, 0, 0.85);
                font-weight: 500;
                &:first-child {
                  padding-left: 24px;
                }
              }
            }
            .tools {
              .iconfont-v3 {
                cursor: pointer;
                color: #0a7ff5;
                &:hover {
                  color: #0060cf;
                }
              }
            }
          }
        }
      }
    }
    .save {
      margin-left: 100px;
      width: 100px;
    }
  }
</style>
